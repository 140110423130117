import {SALPrinterActions} from "../actions/SALPrinterActions";
import {getSALFormat} from "../network/SALPrinterNetworkAPI";
import {StringConstant} from "../../utils/StringConstant";
import {ResponseStatus} from "../../utils/ResponseStatus";

export const SALPrinterReducer = (state, action) => {
    switch (action.type) {
        case SALPrinterActions.CHANGE_PRINTER_SUCCESS:
            return {...state, printerChangeSuccess: true, printerSerial: action.printerSerial};

        case SALPrinterActions.CHANGE_PRINTER_SUCCESS_RESET:
            return {...state, printerChangeSuccess: false};

        case SALPrinterActions.GET_SAL_FORMAT:
            const {dispatcher} = action.data;
            getSALFormat(dispatcher, StringConstant.AVERY_PRINTER);
            return state;

        case SALPrinterActions.GET_SAL_FORMAT_RESPONSE:
            return {
                ...state,
                isPrintingEnabled: true,
                salFormat: action.printerFormat,
                salFormatResponseStatus: action.responseCode
            };

        case SALPrinterActions.GET_SAL_FORMAT_RESPONSE_ERROR:
            return {
                ...state,
                isLoading: false,
                salFormatResponseStatus: action.responseCode
            };

        case SALPrinterActions.LOADING:
            return {...state, salFormatResponseStatus: ResponseStatus.LOADING};

        case SALPrinterActions.RESET_RESPONSE:
            return {...state, salFormatResponseStatus: null};

        case SALPrinterActions.INDUCT_WITHOUT_PRINTER:
            return {...state, salFormatResponseStatus: null, salFormat: null, isPrintingEnabled: false};

        default:
            return state;
    }
}
import {inductPackage} from '../network/ReceivePackageNetworkAPI';
import {ReceivePackageActions} from "../actions/ReceivePackageActions";
import {StringConstant} from "../../utils/StringConstant";
import {getRegion} from "../../utils/network/BaseURLUtils";
import {Logger} from "@amzn/dolphin-web-framework";

export const ReceivePackageReducer = (state, action) => {
    switch (action.type) {
        case ReceivePackageActions.INDUCT_PACKAGE:
            const {dispatcher, packageId, inductLocation, isVolumetricScan, isPrintingEnabled} = action.data;
            if (validatePackage(isPrintingEnabled, packageId)) {
                inductPackage(dispatcher, packageId, inductLocation, isVolumetricScan, isPrintingEnabled);
            }
            else {
                Logger.log.error("Error in Induct: Package ID validation failed", {packageId: packageId});
            }
            return state;

        case ReceivePackageActions.INDUCT_PACKAGE_RESPONSE: {
            return {...state, inductState: action.inductState};
        }

        case ReceivePackageActions.INDUCT_PACKAGE_RESPONSE_ERROR: {
            return {...state, inductState: action.inductState};
        }

        case ReceivePackageActions.RESET_INDUCT_STATE:
            return {...state, inductState: {
                responseStatus: null,
                responseCode: null,
                isInternetNotPresent: null,
                isUserNotAuthorized: null,
                itemId: null,
                soundType: null,
                isApiError: null,
        }};

        default:
            return state;
    }
}


function validatePackage(isPrintingEnabled, packageId) {
    const EU_TBA_LENGTH_FOR_DATA_METRIC = 12;
    const TBA_LENGTH_15_FOR_CODE_128    = 15;
    const TBA_LENGTH_16_FOR_CODE_128    = 16;
    const TCDA_LENGTH_29_FOR_QR_CODE    = 29;

    if (isPrintingEnabled) {
        /**
         * Check if Scanning is happening in NA region, it should start with TBA Where as in EU region it should be
         * of length 12
         */
        // TODO: Get Information from Dolphin Framework
        const userRegion = getRegion();
        if (StringConstant.REGION_NA === userRegion) {
            return (packageId.length === TBA_LENGTH_15_FOR_CODE_128
                || packageId.length === TBA_LENGTH_16_FOR_CODE_128
                || packageId.length === TCDA_LENGTH_29_FOR_QR_CODE);
        } else if (StringConstant.REGION_EU === userRegion) {
            return (packageId.length === EU_TBA_LENGTH_FOR_DATA_METRIC
                || packageId.length === TBA_LENGTH_16_FOR_CODE_128
                || packageId.length === TBA_LENGTH_15_FOR_CODE_128
                || packageId.length === TCDA_LENGTH_29_FOR_QR_CODE);
        } else {
            return true;
        }
    }
    return true;
}
/**
 * Routes for all Induct screens
 */
export const Routes = {
    // Select Induct task screen
    HOME: "/",

    // Enter Scan Location Screen
    INDUCT_LOCATION_WITH_PROPS: "/inductLocation/:flow",
    INDUCT_LOCATION_WITHOUT_PROPS: "/inductLocation/",

    // Receive Package without printing
    RECEIVE_PACKAGE_WITH_PROPS: "/receivePackage/:flow/:inductLocation",
    RECEIVE_PACKAGE_WITHOUT_PROPS: "/receivePackage/",

    // Change Printer
    SCAN_PRINTER_WITH_PROPS: "/scanPrinter/:flow/:inductLocation",
    SCAN_PRINTER_WITHOUT_PROPS: "/scanPrinter/",

    //No Internet
    NO_INTERNET: "/noInternet"
};

/**
 * Flows in Induct module
 */
export const Flow = {
    // Induct without SAL Printing
    SCAN: "scan",

    // Induct with SAL Printing
    PRINT: "print"
};
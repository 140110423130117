export const ResponseCode = {
    // Error
    SCHEDULED_FOR_FUTURE: "SCHEDULED_FOR_FUTURE",
    MISSING_CONTAINER_PLAN: "MISSING_CONTAINER_PLAN",
    INVALID_MCO_CYCLE: "INVALID_MCO_CYCLE",
    UNSELECTED_STATION_INDUCT_CYCLE: "UNSELECTED_STATION_INDUCT_CYCLE",
    MRC_REFRESH_FAILED: "MRC_REFRESH_FAILED",
    INVALID_MCO_XPT_PARENT_CYCLE: "INVALID_MCO_XPT_PARENT_CYCLE",
    PACKAGE_OUTSIDE_JURISDICTION: "PACKAGE_OUTSIDE_JURISDICTION",
    INVALID_TRACKING_ID: "INVALID_TRACKING_ID",
    INVALID_INDUCT_LOCATION: "INVALID_INDUCT_LOCATION",
    INVALID_EMPLOYEE_ALIAS: "INVALID_EMPLOYEE_ALIAS",
    INVALID_STATION_CODE: "INVALID_STATION_CODE",
    OPERATOR_AND_STATION_DIFFERENT: "OPERATOR_AND_STATION_DIFFERENT",
    UNKNOWN_ERROR: "UNKNOWN_ERROR",
    AT_WRONG_STATION: "AT_WRONG_STATION",
    READY_FOR_FC_RETURN: "READY_FOR_FC_RETURN",
    SUCCESS: "SUCCESS",
    UNSUPPORTED_PRINTER_TYPE: "UNSUPPORTED_PRINTER_TYPE",
    SAL_TEMPLATE_NOT_FOUND: "SAL_TEMPLATE_NOT_FOUND",

    // Warning
    SIDELINE: "SIDELINE",
    SHIPMENT_IN_ALERT: "SHIPMENT_IN_ALERT"
}
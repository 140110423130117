import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {Routes} from "./utils/Routes";
import SALPrinterContextProvider from "./scanPrinter/context/SALPrinterContext";
import InductLocationContextProvider from "./inductLocation/context/InductLocationContext";
import ReceivePackageContextProvider from "./receivePackage/context/ReceivePackageContext";
import AppNavigationContextProvider from "./appNavigation/context/AppNavigationContext";
import AppNavigation from "./appNavigation/AppNavigation";
import "@amzn/dolphin-uiweb-framework/dist/index.css";
import Column from "@amzn/meridian/column";
import {FeatureManager, PUMAMetricHelper, PlayerHelper, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {Logger} from "@amzn/dolphin-web-framework";
import {NoInternetView, TrainingBlockerView} from "@amzn/dolphin-uiweb-framework";
import noInternetImage from "../images/no_internet.png";
import {getRegion, getStage} from "./utils/network/BaseURLUtils";
import {MeshClient} from "@amzn/dolphin-web-framework";
import {PlayerConstants} from "./player/PlayerConstant";
import PlayerContextProvider from "./player/context/PlayerContext";

const HomeView = React.lazy(() => import("./home/HomeView"));
const ScanPrinterView = React.lazy(() => import("./scanPrinter/ScanPrinterView"));
const ScanPackageView = React.lazy(() => import("./receivePackage/ScanPackageView"));
const ScanInductLocationView = React.lazy(() => import("./inductLocation/ScanInductLocationView"));
function publishRoutePath(location) {
    const metricName = location.pathname;
    PUMAMetricHelper.publishCountToDolphinCSM(metricName);
}

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {loadFeatures: true, trainingBlocker: false};
    }

    async componentWillMount() {
        await MeshClient.connectWebSocket();
        await FeatureManager.getFeatures(getStage(), getRegion());
        PlayerHelper.init(PlayerConstants.PROCESS_PATH, PlayerConstants.VERSION, getStage(), getRegion())
            .then(() => {
                PlayerHelper.sendMessage(new window.DolphinPlayer.Induct.ProcessSelectedEvent(), new window.DolphinPlayer.Induct.TaskSelectState());
            });
        this.setState({loadFeatures: false});
        publishRoutePath(this.props.location);
        this.onRouteSwitch = this.props.history.listen(() => {
            publishRoutePath(this.props.location);
        });
    }

    componentWillUnmount() {
        MeshClient.closeWebSocketConnection();
        PlayerHelper.terminate();
        this.onRouteSwitch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname ||
            this.props.location.search !== prevProps.location.search) {
            Logger.log.info("Route Change", {previousPath: prevProps.location, currentPath: this.props.location});
        }
    }

    renderTrainingBlockerView = () => {
        return <TrainingBlockerView
            title={"training_blocker_title"}
            description={"training_blocker_desc"}
            buttonText={"got_it"}
            buttonOnClick={() => NativeMeshInteractor.exitModule()}/>
    }

    render() {
        return (
            <PlayerContextProvider>
                <AppNavigationContextProvider history={this.props.history}>
                    <Switch>
                        <Route exact path={Routes.NO_INTERNET}>
                            <NoInternetView
                                title={"no_internet"}
                                openSettingsButtonText={"device_setting"}
                                noInternetImage={noInternetImage}/>
                        </Route>
                    </Switch>
                    {this.state.trainingBlocker ? this.renderTrainingBlockerView() :
                        <Column height="100%" spacing="none">
                            {this.state.loadFeatures ? <Loader/> : null}
                            <AppNavigation/>
                            <div className="content">
                                <SALPrinterContextProvider>
                                    <React.Suspense fallback={<Loader/>}>
                                        <Switch>
                                            <Route exact path={Routes.HOME} component={HomeView}/>
                                        </Switch>
                                        <InductLocationContextProvider>
                                            <Switch>
                                                <Route exact path={Routes.INDUCT_LOCATION_WITH_PROPS}
                                                       component={ScanInductLocationView}/>
                                            </Switch>
                                        </InductLocationContextProvider>
                                        <ReceivePackageContextProvider>
                                            <Switch>
                                                <Route exact path={Routes.RECEIVE_PACKAGE_WITH_PROPS}
                                                       component={ScanPackageView}/>
                                                <Route exact path={Routes.SCAN_PRINTER_WITH_PROPS}
                                                       component={ScanPrinterView}/>
                                            </Switch>
                                        </ReceivePackageContextProvider>
                                    </React.Suspense>
                                </SALPrinterContextProvider>
                            </div>
                        </Column>
                    }
                </AppNavigationContextProvider>
            </PlayerContextProvider>
        );
    }
}

export default withRouter(App);

import {ResponseStatus} from "../../utils/ResponseStatus";
import {ResponseCode} from "../../utils/ResponseCode";

let RESPONSE_CODE_TO_RESOURCE = new Map();
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.SUCCESS, "induct.success.package_received")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.SCHEDULED_FOR_FUTURE, "induct.error.scheduled_for_future")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.MISSING_CONTAINER_PLAN, "induct.error.missing_container_plan")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_MCO_CYCLE, "induct.error.invalid_mco_cycle")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.UNSELECTED_STATION_INDUCT_CYCLE, "induct.error.unselected_station_induct_cycle")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.MRC_REFRESH_FAILED, "induct.error.mrc_refresh_failed")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_MCO_XPT_PARENT_CYCLE, "induct.error.invalid_mco_xpt_parent_cycle")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.PACKAGE_OUTSIDE_JURISDICTION, "induct.error.package_outside_jurisdiction")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_TRACKING_ID, "induct.error.invalid_tracking_id")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_INDUCT_LOCATION, "induct.error.invalid_induct_location")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_EMPLOYEE_ALIAS, "induct.error.invalid_employee_alias")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.INVALID_STATION_CODE, "induct.error.invalid_station_code")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.OPERATOR_AND_STATION_DIFFERENT, "induct.error.operator_and_station_different")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.UNKNOWN_ERROR, "induct.error.unknown_error")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.AT_WRONG_STATION, "induct.error.at_wrong_station_induct")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.READY_FOR_FC_RETURN, "induct.error.package_ready_for_fc_return")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.SIDELINE, "induct.error.sideline_package_induct")
RESPONSE_CODE_TO_RESOURCE.set(ResponseCode.SHIPMENT_IN_ALERT, "induct.error.shipment_in_alert")

export function getAlertMessage(intl, responseStatus, responseCode, trackingId) {
    switch (responseStatus) {
        case ResponseStatus.SUCCESS:
            return intl.formatMessage(
                {id: RESPONSE_CODE_TO_RESOURCE.get(responseCode)},
                {id: trackingId}
            );
        case ResponseStatus.WARN:
            return intl.formatMessage(
                {id: defaultIfNull(RESPONSE_CODE_TO_RESOURCE.get(responseCode), "induct.error.warningPackage")},
                {id: trackingId}
            );
        case ResponseStatus.ERROR:
            return intl.formatMessage(
                {id: defaultIfNull(RESPONSE_CODE_TO_RESOURCE.get(responseCode), "induct.error.errorPackage")},
                {id: trackingId}
            );
        default:
            return intl.formatMessage(
                {id: RESPONSE_CODE_TO_RESOURCE.get(ResponseCode.UNKNOWN_ERROR)},
                {id: trackingId}
            );
    }
}

function defaultIfNull(string, defaultString) {
    return (string ? string : defaultString);
}

export function getPrintInput(averyPrinterObj) {
    let printInput = '';
    for (const key in averyPrinterObj) {
        if (printInput === '')
            printInput += averyPrinterObj[key];
        else
            printInput += `|${averyPrinterObj[key]}`;
    }
    return printInput;
}
import {ReceivePackageActions} from "../actions/ReceivePackageActions";
import {Endpoints} from "../../utils/network/Enpoints";
import {axiosPOST} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, Constants} from "@amzn/dolphin-web-framework";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getPrintInput} from "../utils/utils";
import {Logger} from "@amzn/dolphin-web-framework";
import {StringConstant} from "../../utils/StringConstant";
export function inductPackage(dispatcher, packageId, inductLocation, isVolumetricScan, isPrintingEnabled) {
    const requestData = {
        "trackingId": packageId,
        "inductLocation": inductLocation,
        "isVolumetricPackage": isVolumetricScan
    };
    postInductPackage(dispatcher, requestData, isPrintingEnabled);
}


async function postInductPackage(dispatcher, requestData, isPrintingEnabled) {
    let startTime = Date.now();
    try {
        const zebraStatus = await NativeMeshInteractor.getZebraStatus();

        if(zebraStatus === "false"){
            const usbConnection = NativeMeshInteractor.connectUsbZebra();
            Logger.log.info("Connect USB Zebra response: " +usbConnection);
        }

        const isZebraPrinterConnected = await NativeMeshInteractor.getZebraStatus();

        if(isZebraPrinterConnected === "false") {
            const response = await axiosPOST("InductPackage", Endpoints.POST_INDUCT_PACKAGE, requestData);
            if (response != null){

                dispatcher({
                    type: ReceivePackageActions.INDUCT_PACKAGE_RESPONSE,
                    inductState: {
                        responseStatus: response.data.userOutput.displayOutput.responseStatus,
                        responseCode: response.data.userOutput.displayOutput.responseCode,
                        itemId: response.data.trackingId,
                        soundType: response.data.userOutput.audioCueOutput.soundType
                    }
                });
                if(isPrintingEnabled) {
                    NativeMeshInteractor.printAveryLabel(getPrintInput(response.data.userOutput.salPrintOutput.AVERY_PRINTER), "InductSAL");

                    MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA_DIRECTED_INDUCT_PRINT, response, AnalyticEventKeys.Modules.DIRECTED_INDUCT,
                        response.data.trackingId, response.data.userOutput.displayOutput.responseCode, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE);
                }
                else {
                    MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA_DIRECTED_INDUCT, response, AnalyticEventKeys.Modules.DIRECTED_INDUCT,
                        response.data.trackingId, response.data.userOutput.displayOutput.responseCode, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE);
                }

            }
            else {
                dispatcher({
                    type: ReceivePackageActions.INDUCT_PACKAGE_RESPONSE_ERROR,
                    inductState: {isApiError: true}
                });
            }
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.INDUCT_PACKAGE, startTime);
        }
        else{
            const zplRequest = {
                "trackingId": requestData.trackingId,
                "inductLocation": requestData.inductLocation,
                "isVolumetricPackage": requestData.isVolumetricPackage,
                "printerType": StringConstant.ZEBRA_PRINTER
            };
            const zebraResponse = await axiosPOST("InductPackage", Endpoints.POST_INDUCT_PACKAGE, zplRequest);

            if (zebraResponse != null){
                dispatcher({
                    type: ReceivePackageActions.INDUCT_PACKAGE_RESPONSE,
                    inductState: {
                        responseStatus: zebraResponse.data.userOutput.displayOutput.responseStatus,
                        responseCode: zebraResponse.data.userOutput.displayOutput.responseCode,
                        itemId: zebraResponse.data.trackingId,
                        soundType: zebraResponse.data.userOutput.audioCueOutput.soundType
                    }
                });
                if(isPrintingEnabled) {
                    const zebraPrinterResponse = await NativeMeshInteractor.printZebra(zebraResponse.data.userOutput.salPrintOutput.ZPL_PRINTER.SAL_DATA);
                    Logger.log.info(zebraPrinterResponse);

                    MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA_DIRECTED_INDUCT_PRINT, zebraResponse, AnalyticEventKeys.Modules.DIRECTED_INDUCT,
                        zebraResponse.data.trackingId, zebraResponse.data.userOutput.displayOutput.responseCode, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE);
                }
                else {
                    MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA_DIRECTED_INDUCT, zebraResponse, AnalyticEventKeys.Modules.DIRECTED_INDUCT,
                        zebraResponse.data.trackingId, zebraResponse.data.userOutput.displayOutput.responseCode, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE);
                }

            }
            else {
                dispatcher({
                    type: ReceivePackageActions.INDUCT_PACKAGE_RESPONSE_ERROR,
                    inductState: {isApiError: true}
                });
            }
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.INDUCT_PACKAGE, startTime);
        }

    } catch (error) {
        Logger.log.info("Error:",error);
        let inductState = {isApiError: true};

        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            inductState = {isInternetNotPresent: true};
        }
        else if (error.status === "COMP_USER_NOT_FOUND") {
            inductState = {isUserNotAuthorized: true};
        }
        dispatcher({
            type: ReceivePackageActions.INDUCT_PACKAGE_RESPONSE_ERROR,
            inductState: inductState
        });
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.INDUCT_PACKAGE, startTime, true);
    }
}
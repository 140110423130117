import {PlayerHelper} from "@amzn/dolphin-web-framework";
import {ApplicationActions} from "../action/ApplicationActions";
import {mapErrorToPlayerEnum} from "../PlayerConstant";

export const PlayerReducer = (state, action) => {
    try {
        if(window && window.DolphinPlayer) {
            let event, statePlayer;
            switch (action.type) {
                case ApplicationActions.TASK_SELECTION_INDUCT: {
                    if(action.data.withPrint){
                        event = new window.DolphinPlayer.Induct.TaskSelectedEvent(window.DolphinPlayer.Induct.InductTask.PRINT_LABEL_AND_RECEIVE);
                        statePlayer = new window.DolphinPlayer.Induct.LocationScanState(false);
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, backState: statePlayer};
                    }
                    else{
                        event = new window.DolphinPlayer.Induct.TaskSelectedEvent(window.DolphinPlayer.Induct.InductTask.RECEIVE_PACKAGES);
                        statePlayer = new window.DolphinPlayer.Induct.LocationScanState(false);
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, backState: statePlayer};
                    }
                }
                case ApplicationActions.SCAN_INDUCT_LOCATION: {
                    if(action.data.isValid){
                        event = new window.DolphinPlayer.Induct.BarcodeEnteredEvent(true);
                        statePlayer = new window.DolphinPlayer.Induct.PackageScanState(state.isFirstPackage, false);
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, isHelpEnabled: true, backState: statePlayer};
                    }
                    else{
                        event = new window.DolphinPlayer.Induct.BarcodeEnteredEvent(false);
                        statePlayer = new window.DolphinPlayer.Induct.LocationScanState(true);
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, backState: statePlayer};
                    }
                }
                case ApplicationActions.SCAN_PACKAGE: {
                    if (action.data.responseStatus === "SUCCESS") {
                        if (state.isManual) {
                            event = new window.DolphinPlayer.Induct.BarcodeEnteredEvent(true);
                            statePlayer = new window.DolphinPlayer.Induct.PackageScanState(state.isFirstPackage, false);
                            PlayerHelper.sendMessage(event, statePlayer);
                        } else {
                            event = new window.DolphinPlayer.Induct.BarcodeScannedEvent(true);
                            statePlayer = new window.DolphinPlayer.Induct.PackageScanState(state.isFirstPackage, false);
                            PlayerHelper.sendMessage(event, statePlayer);
                        }
                        return {...state, isFirstPackage: false, isManual: false, isHelpEnabled: true, backState: statePlayer};
                    } else {
                        const errorType = mapErrorToPlayerEnum(action.data.responseCode);
                        if (state.isManual) {
                            event = new window.DolphinPlayer.Induct.BarcodeEnteredEvent(false);
                            statePlayer = new window.DolphinPlayer.Induct.PackageScanState(state.isFirstPackage, true, errorType);
                            PlayerHelper.sendMessage(event, statePlayer)
                        } else {
                            event = new window.DolphinPlayer.Induct.BarcodeScannedEvent(false);
                            statePlayer = new window.DolphinPlayer.Induct.PackageScanState(state.isFirstPackage, true, errorType);
                            PlayerHelper.sendMessage(event, statePlayer)
                        }
                        return {...state, isManual: false, isHelpEnabled: true, backState: statePlayer};
                    }
                }
                case ApplicationActions.HELP_OPTION_SELECTED: {
                    event = new window.DolphinPlayer.Induct.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Induct.HelpCenterOption.MANUAL_PACKAGE_SCAN);
                    statePlayer = new window.DolphinPlayer.Induct.ManualPackageScanState();
                    PlayerHelper.sendMessage(event, statePlayer);
                    return state;
                }
                case ApplicationActions.HELP_SELECTED: {
                    if(state.isHelpEnabled) {
                        event = new window.DolphinPlayer.Induct.HelpCenterOpenedEvent();
                        statePlayer = new window.DolphinPlayer.Induct.HelpCenterState(window.DolphinPlayer.Induct.HelpCenterContext.PACKAGE_SCAN);
                        PlayerHelper.sendMessage(event, statePlayer);
                    } else {
                        event = new window.DolphinPlayer.Induct.HelpCenterOpenedEvent();
                        statePlayer = new window.DolphinPlayer.Induct.HelpCenterState(window.DolphinPlayer.Induct.HelpCenterContext.NOT_IMPLEMENTED);
                        PlayerHelper.sendMessage(event, statePlayer);
                    }
                    return state;
                }
                case ApplicationActions.HELP_CLOSED: {
                    event = new window.DolphinPlayer.Induct.ActionDeclinedEvent();
                    statePlayer = (state.backState) ? state.backState: new window.DolphinPlayer.Induct.TaskSelectState();
                    PlayerHelper.sendMessage(event, statePlayer);
                    return {...state, backState: statePlayer}
                }
                case ApplicationActions.BACK_SELECTED: {
                    if(state.isHelpEnabled && !(state.isManual)){
                        event = new window.DolphinPlayer.Induct.BackActionSelectedEvent();
                        statePlayer = state.backState;
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, backState: statePlayer, isHelpEnabled: true};
                    } else if(!(state.isHelpEnabled)){
                        event = new window.DolphinPlayer.Induct.BackActionSelectedEvent();
                        statePlayer = new window.DolphinPlayer.Induct.TaskSelectState();
                        PlayerHelper.sendMessage(event, statePlayer);
                        return {...state, backState: statePlayer, isHelpEnabled: false};
                    }
                    return state;
                }
                default:
                    return state;
            }
        }
        else {
            console.log("Drona Player not initialised, msg not sent");
            return {
                ...state,
                isInitialized: false,
            };
        }
    } catch(error) {
        console.log("Player app message exception", error);
    }
}

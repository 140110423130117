import {inductLocations} from '../network/InductLocationNetworkAPI'
import {InductLocationActions} from "../actions/InductLocationActions";
import {ResponseStatus} from "../../utils/ResponseStatus";

export const InductLocationReducer = (state, action) => {
    switch (action.type) {
        case InductLocationActions.GET_INDUCT_LOCATIONS:
            const {dispatcher} = action.data;
            inductLocations(dispatcher);
            return {...state, responseStatus: null, locations: null};

        case InductLocationActions.GET_INDUCT_LOCATIONS_RESPONSE:
            return {...state, responseStatus: ResponseStatus.SUCCESS, locations: action.locations};

        case InductLocationActions.GET_INDUCT_LOCATIONS_RESPONSE_ERROR:
            return {...state, responseStatus: action.responseStatus};

        case InductLocationActions.RESET_RESPONSE:
            return {...state, responseStatus: null};

        case InductLocationActions.LOADING:
            return {...state, responseStatus: ResponseStatus.LOADING};

        default:
            return state;
    }
}
import React, {createContext, useReducer} from "react";
import {ReceivePackageReducer} from "../reducers/ReceivePackageReducer";

export const ReceivePackageContext = createContext();

const ReceivePackageContextProvider = (props) => {
    const initState = {
        inductState: {
            responseStatus: null,
            responseCode: null,
            isInternetNotPresent: null,
            isUserNotAuthorized: null,
            itemId: null,
            soundType: null,
            isApiError: null,
        }
    };
    const [state, dispatch] = useReducer(ReceivePackageReducer, initState);

    return (
        <ReceivePackageContext.Provider value={{state, dispatch}}>
            {props.children}
        </ReceivePackageContext.Provider>
    )
}

export default ReceivePackageContextProvider;

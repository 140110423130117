import React, {createContext, useReducer} from "react";
import {InductLocationReducer} from "../reducers/InductLocationReducer";

export const InductLocationContext = createContext();

const InductLocationContextProvider = (props) => {
    const initState = {
        locations: null,
        responseStatus: null
    };
    const [state, dispatch] = useReducer(InductLocationReducer, initState);

    return (
        <InductLocationContext.Provider value={{state, dispatch}}>
            {props.children}
        </InductLocationContext.Provider>
    )
}

export default InductLocationContextProvider;

import {Constants} from "@amzn/dolphin-web-framework";

export function getNodeInductServiceBaseURL() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://dolphin-preprod.amazon.com";
                case Constants.Region.REGION_EU: return "https://dolphin-preprod.amazon.eu";
                case Constants.Region.REGION_IN: return "https://dolphin-preprod.amazon.eu/in";
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://dolphin-preprod.amazon.com";
                case Constants.Region.REGION_EU: return "https://dolphin-preprod.amazon.eu";
                case Constants.Region.REGION_FE: return "https://dolphin-fe-preprod.amazon.com";
                case Constants.Region.REGION_IN: return "https://dolphin-preprod.amazon.eu/in";
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://dolphin.amazon.com";
                case Constants.Region.REGION_EU: return "https://dolphin.amazon.eu";
                case Constants.Region.REGION_FE: return "https://dolphin-fe.amazon.com";
                case Constants.Region.REGION_IN: return "https://dolphin.amazon.eu/in";
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
}

export function getPandaTokenExchangeURL() {
    switch(getRegion()) {
        case Constants.Region.REGION_NA: return "https://api.amazon.com/auth/token";
        case Constants.Region.REGION_IN:
        case Constants.Region.REGION_EU: return "https://api.amazon.co.uk/auth/token";
        case Constants.Region.REGION_FE: return "https://api.amazon.co.jp/auth/token";
        // no default
    }
    /* istanbul ignore next */
}

export function getRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return Constants.Region.REGION_NA;
    else if (hostname.startsWith('eu')) return Constants.Region.REGION_EU;
    else if (hostname.startsWith('fe')) return Constants.Region.REGION_FE;
    else if (hostname.startsWith('in')) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_NA;
}

export function getAnalyticsRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return Constants.Region.REGION_NA;
    else if (hostname.startsWith('eu')) return Constants.Region.REGION_EU;
    else if (hostname.startsWith('fe')) return Constants.Region.REGION_FE;
    else if (hostname.startsWith('in')) return Constants.Region.REGION_EU;
    return Constants.Region.REGION_NA;
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null && hostname[1].startsWith('iew')) {
        const stage = hostname[1];
        if (stage.endsWith('integ')) return Constants.Stage.BETA;
        else if (stage.endsWith('master')) return Constants.Stage.GAMMA;
        return Constants.Stage.PROD;
    }
    return Constants.Stage.GAMMA;
}

export function getPUMARegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return 'na';
    else if (hostname.startsWith('eu')) return 'eu';
    else if (hostname.startsWith('fe')) return 'jp';
    else if (hostname.startsWith('in')) return 'eu';
    return 'jp';
}

export function getPUMACountry() {
    const hostname = window.location.hostname;
    if (hostname.startsWith('na')) return 'US';
    else if (hostname.startsWith('eu')) return 'UK';
    else if (hostname.startsWith('fe')) return 'JP';
    else if (hostname.startsWith('in')) return 'IN';
    return 'JP';
}

export function getPUMAStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith('integ')) return 'test';
        else if (stage.endsWith('master')) return 'test';
        return 'prod';
    }
    return 'test';
}

export function getLoggerUrl() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            return "https://3rgkbcnf02.execute-api.us-west-2.amazonaws.com/prod/v1/log";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://x1mqmcrgp4.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://apjc9dw0wd.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://n8vnwa8bqi.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://yw5e1einve.execute-api.eu-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://qovu9dbtkj.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://b9ue0iocag.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://whxu9wlk8l.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://0juf6t2m08.execute-api.eu-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
}
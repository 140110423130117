import React, {createContext, useReducer} from "react";
import { PlayerReducer } from "../reducer/PlayerReducer";

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {
    const initState = {
        isInitialized: true,
        isFirstPackage: true,
        isManual: false,
        backState: undefined,
        isHelpEnabled: false
    };
    const [statePlayer, dispatchPlayer] = useReducer(PlayerReducer, initState);

    return (
        <PlayerContext.Provider value={{statePlayer, dispatchPlayer}}>
            {props.children}
        </PlayerContext.Provider>
    )
}

export default PlayerContextProvider;

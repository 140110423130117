import beep from "../../audio/res/beep.ogg";
import beep_error from "../../audio/res/beep_error.ogg";
import beep_warning from "../../audio/res/beep_warning.mp3";
import content_scanning from "../../audio/res/content_scanning.mp3";
import exchange from "../../audio/res/exchange.mp3";
import open_box from "../../audio/res/open_box.mp3";
import pickup from "../../audio/res/pickup.mp3";
import receive_error from "../../audio/res/receive-error.ogg";
import return_sound from "../../audio/res/return_sound.mp3";
import scanner_beep from "../../audio/res/scanner_beep.ogg";
import scanner_beep_charge from "../../audio/res/scanner-beep-charge.ogg";
import scanner_beep_major_scale_up from "../../audio/res/scanner-beep-major-scale-up.ogg";
import scanner_beep_major_scale_down from "../../audio/res/scanner-beep-minor-scale-down.ogg";
import scanner_beep_triple from "../../audio/res/scanner-beep-triple.ogg";
import schedule_delivery from "../../audio/res/scheduled_delivery.mp3";
import scheduled_pantry from "../../audio/res/scheduled_pantry.mp3";
import warn from "../../audio/res/warn.ogg";
import silent from "../../audio/res/silent.mp3";

function initializeSoundObject(resource) {
    let audio = new Audio(resource);
    audio.preload = 'auto';
    return audio
}

let beep_sound = initializeSoundObject(beep);
let beep_error_sound = initializeSoundObject(beep_error);
let beep_warning_sound = initializeSoundObject(beep_warning);
let content_scanning_sound = initializeSoundObject(content_scanning);
let exchange_sound = initializeSoundObject(exchange);
let open_box_sound = initializeSoundObject(open_box);
let pickup_sound = initializeSoundObject(pickup);
let receive_error_sound = initializeSoundObject(receive_error);
let return_sound_sound = initializeSoundObject(return_sound);
let scanner_beep_sound = initializeSoundObject(scanner_beep);
let scanner_beep_charge_sound = initializeSoundObject(scanner_beep_charge);
let scanner_beep_major_scale_up_sound = initializeSoundObject(scanner_beep_major_scale_up);
let scanner_beep_major_scale_down_sound = initializeSoundObject(scanner_beep_major_scale_down);
let scanner_beep_triple_sound = initializeSoundObject(scanner_beep_triple);
let schedule_delivery_sound = initializeSoundObject(schedule_delivery);
let scheduled_pantry_sound = initializeSoundObject(scheduled_pantry);
let warn_sound = initializeSoundObject(warn);
let silent_sound = initializeSoundObject(silent);

export const SoundType = {
    ATTENTION: beep_warning_sound,
    BEEP: beep_sound,
    BEEP_ERROR: beep_error_sound,
    BEEP_WARNING: beep_warning_sound,
    CONTENT_SCANNING: content_scanning_sound,
    ERROR: beep_error_sound,
    EXCHANGE: exchange_sound,
    OPEN_BOX_DELIVERY: open_box_sound,
    PICKUP: pickup_sound,
    RECEIVE_ERROR: receive_error_sound,
    RETURN: return_sound_sound,
    SCANNER_BEEP: scanner_beep_sound,
    SCANNER_BEEP_CHARGE: scanner_beep_charge_sound,
    SCANNER_BEEP_MAJOR_SCALE_UP: scanner_beep_major_scale_up_sound,
    SCANNER_BEEP_MAJOR_SCALE_DOWN: scanner_beep_major_scale_down_sound,
    SCANNER_BEEP_TRIPLE: scanner_beep_triple_sound,
    SCHEDULE_DELIVERY: schedule_delivery_sound,
    SCHEDULED_PANTRY_SUCCESS: scheduled_pantry_sound,
    SUCCESS: beep_sound,
    WARN: warn_sound,
    SILENT: silent_sound,
    SCHEDULED_DELIVERY_SERVICE_REQD: schedule_delivery_sound
};
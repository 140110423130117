export const AppNavigationActions = {
    SETUP_HELP: "SETUP_HELP",
    OPEN_HELP: "OPEN_HELP",
    CLOSE_HELP: "CLOSE_HELP",
    ERROR_COL: "ERROR_COL",
    RESET_COL: "RESET_COL",
    SET_LOADER: "SET_LOADER",
    CLOSE_ON_BACK: "CLOSE_ON_BACK",
    CALLBACK_ON_BACK: "CALLBACK_ON_BACK",
    SHOW_ALERT: "SHOW_ALERT",
    CLEAR_ALERT: "CLEAR_ALERT"
};
import React, {createContext, useContext, useReducer} from "react";
import {AppNavigationActions} from "../actions/AppNavigationActions";
import {AppNavigationReducer} from "../reducers/AppNavigationReducer";
import {injectIntl} from "react-intl";
import {interceptHelpClose} from "../../../handler/BackPress";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ApplicationActions} from "../../player/action/ApplicationActions";

export const AppNavigationContext = createContext();

const AppNavigationContextProvider = (props) => {
    const initState = {
        title: props.intl.formatMessage({id: "induct"}),
        loading: false,
        navType: 'default',
        backEnabled: true,
        helpEnabled: true,
        onBackClicked: null,
        alert: {
            show: false,
            type: "",
            message: "",
            onClose: null
        },
        helpOptions: {
            open: false,
            title: "Help Center",
            pageTitle: "",
            list: []
        },
    };

    const [navState, navDispatch] = useReducer(AppNavigationReducer, initState);
    const {dispatchPlayer} = useContext(PlayerContext);

    const setupHelp = (enabled, options) => {
        navDispatch({type: AppNavigationActions.SETUP_HELP, value: {enabled, options}});
    }

    const openHelp = () => {
        interceptHelpClose();
        dispatchPlayer({type: ApplicationActions.HELP_SELECTED});
        navDispatch({type: AppNavigationActions.OPEN_HELP});
    }

    const closeHelp = () => {
        navDispatch({type: AppNavigationActions.CLOSE_HELP});
    }

    const errorNavCol = () => {
        navDispatch({type: AppNavigationActions.ERROR_COL});
    }

    const resetNavCol = () => {
        navDispatch({type: AppNavigationActions.RESET_COL});
    }

    const closeOnBack = () => {
        navDispatch({type: AppNavigationActions.CLOSE_ON_BACK});
    }

    const callbackOnBack = (callback) => {
        navDispatch({type: AppNavigationActions.CALLBACK_ON_BACK, callback: callback})
    }

    const onBackDefault = () => {
        navDispatch({type: AppNavigationActions.CALLBACK_ON_BACK, callback: props.history.goBack});
    }

    const showLoader = () => {
        navDispatch({type: AppNavigationActions.SET_LOADER, state: true});
    }

    const hideLoader = () => {
        navDispatch({type: AppNavigationActions.SET_LOADER, state: false});
    }

    const showAlert = (alertType, message, onClose) => {
        navDispatch({type: AppNavigationActions.SHOW_ALERT, alertType: alertType, message: message, onClose: onClose});
    }

    const clearAlert = () => {
        navDispatch({type: AppNavigationActions.CLEAR_ALERT});
    }

    return (
        <AppNavigationContext.Provider value={{
            navState, navActions: {
                setupHelp,
                openHelp,
                closeHelp,
                errorNavCol,
                resetNavCol,
                closeOnBack,
                callbackOnBack,
                onBackDefault,
                showLoader,
                hideLoader,
                showAlert,
                clearAlert
            }
        }}>
            {props.children}
        </AppNavigationContext.Provider>
    )
}

export default injectIntl(AppNavigationContextProvider);

import React, {createContext, useReducer} from "react";
import {SALPrinterReducer} from "../reducers/SALPrinterReducer";
import {StringConstant} from "../../utils/StringConstant";

export const SALPrinterContext = createContext();

const SALPrinterContextProvider = (props) => {
    const initState = {
        isPrintingEnabled: false,
        salFormat: null,
        salFormatResponseStatus: null,
        printerType: StringConstant.AVERY_PRINTER,
        printerSerial: null,
        printerChangeSuccess: false
    };

    const [statePrinter, dispatchPrinter] = useReducer(SALPrinterReducer, initState);

    return (
        <SALPrinterContext.Provider value={{statePrinter, dispatchPrinter}}>
            {props.children}
        </SALPrinterContext.Provider>
    )
}

export default SALPrinterContextProvider;

import React, {useContext, useEffect} from "react";
import Column from "@amzn/meridian/column";
import helpTokens from "@amzn/meridian-tokens/base/icon/help";
import {TopNavigationView, HelpView, Loader} from "@amzn/dolphin-uiweb-framework";
import {AppNavigationContext} from "./context/AppNavigationContext";
import help_icon from "../../images/ic_help_coming_up.png"
import {
    clearBackPressedEventListener,
    setBackPressedEventListener
} from "../../handler/BackPress";
import Alert from "../commons/Alert";
import {PlayerContext} from "../player/context/PlayerContext";
import {ApplicationActions} from "../player/action/ApplicationActions";

/**
 * App Navigation components contains the global components for this
 * app which can be accessed from any components in the hierarchy
 * to control Help View, back button behaviour, Loader and Alerts
 */
export default function AppNavigation() {

    const {navState, navActions} = useContext(AppNavigationContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    /**
     * Callback to be called when system back press is called or
     * UI back button/close buttons are pressed to maintain
     * parity in behaviour.
     */
    const onBackPressed = React.useCallback((event) => {
        if (navState.helpOptions.open) {
            navActions.closeHelp();
            dispatchPlayer({type: ApplicationActions.HELP_CLOSED});
        } else if (navState.onBackClicked) {
            navState.onBackClicked.call(event);
            dispatchPlayer({type: ApplicationActions.BACK_SELECTED});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navState.onBackClicked, navState.helpOptions]);

    /**
     * Listeners are initialized for system back press events
     * but won't intercept unless explicitly started
     */
    useEffect(() => {
        setBackPressedEventListener(onBackPressed);
        return clearBackPressedEventListener;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navState.onBackClicked, navState.helpOptions]);

    /**
     * Top Navigation Bar, Help View, Loader and Alerts are common
     * throughout the apps and can be controlled via navActions
     */
    return (
        <Column spacing="small">
            {/* Global transparent loader overlay */}
            {navState.loading && <Loader/>}

            {/* Top Navigation Bar with Help and Back Button */}
            <TopNavigationView
                title={navState.title}
                backgroundType={navState.navType}
                onMenuButtonClicked={onBackPressed}
                optionsMenu={navState.helpEnabled ? [{
                    icon: helpTokens, onClick: navActions.openHelp
                }] : []}/>

            {/* Help View Overlay */}
            {navState.helpOptions.open && <HelpView
                onClose={() => window.history.back()}
                title={navState.helpOptions.title}
                pageTitle={navState.helpOptions.pageTitle}
                list={navState.helpOptions.list}
                help_icon={help_icon}/>}

            {/* Alerts */}
            {navState.alert.show && <Alert
                type={navState.alert.type}
                message={navState.alert.message}
                onClose={navState.alert.onClose}/>}
        </Column>
    );
}

import {Logger, PUMAMetricHelper} from "@amzn/dolphin-web-framework";
import axiosNISClient from "./NISClient";

export async function axiosPOST(metricName, path, requestData) {
    let startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await axiosNISClient.post(path, requestData);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        Logger.log.error(error);
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export async function axiosGET(metricName, path, params) {
    let startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await axiosNISClient.get(path, params);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        Logger.log.error(error);
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export function publishErrorMetric(metricName, startTime, error) {

    //Emitting Failure Metrics
    PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
    PUMAMetricHelper.publishCountToDolphinCSM(metricName+"-failure");
    if (error.status >= 400 && error.status < 500) {
        PUMAMetricHelper.publishCountToDolphinCSM("4xxError");
    }
    else {
        PUMAMetricHelper.publishCountToDolphinCSM("5xxError");
    }
}



import {InductLocationActions} from "../actions/InductLocationActions";
import {ResponseStatus} from "../../utils/ResponseStatus";
import {Endpoints} from "../../utils/network/Enpoints";
import {axiosGET} from "../../utils/network/NetworkUtils";
import {Constants, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {AnalyticEventKeys} from "@amzn/dolphin-web-framework";

export function inductLocations(dispatcher) {
    dispatcher({type: InductLocationActions.LOADING});
    getInductLocations(dispatcher);
}

async function getInductLocations(dispatcher) {
    let startTime = Date.now();
    try {
        const response = await axiosGET("getInductLocations", Endpoints.GET_INDUCT_LOCATIONS);
        dispatcher({
            type: InductLocationActions.GET_INDUCT_LOCATIONS_RESPONSE,
            locations: response.data.locations
        });
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.INDUCT_INDUCT_LOCATION, startTime);
    } catch(error) {
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            dispatcher({
                type: InductLocationActions.GET_INDUCT_LOCATIONS_RESPONSE_ERROR,
                responseStatus: ResponseStatus.NO_INTERNET
            });
        }
        else {
            dispatcher({
                type: InductLocationActions.GET_INDUCT_LOCATIONS_RESPONSE_ERROR,
                responseStatus: ResponseStatus.ERROR
            });
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.INDUCT_INDUCT_LOCATION, startTime, true);
    }
}
